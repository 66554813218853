import React, { useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { useFormFields } from "../libs/hooksLib";

function ContactForm({ isLoading, onSubmit, ...props }) {
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        message:""
      });
const [isProcessing, setIsProcessing] = useState(false);
isLoading = isProcessing || isLoading;
  function validateForm() {
    return (
        fields.email !== "" &&
        fields.message !== ""
      );
  }

  async function handleSubmitClick(event) {
    event.preventDefault();
    setIsProcessing(false);
    onSubmit(fields);
  }

  return (
    <div>
      <b>Thank you for your interest in Stock Checker</b>
      <br /><br />
      <p className="text-white">Reach out to us below and we will be in touch.
        </p>
      <form onSubmit={handleSubmitClick}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            onChange={handleFieldChange}
            value = {fields.email}
          />
        </FormGroup>
        <FormGroup controlId="message" bsSize="large">
          <ControlLabel>Message</ControlLabel>
          <FormControl
            componentClass="textarea"
            onChange={handleFieldChange}
            value = {fields.message}
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!validateForm()}
          type="submit"
          isLoading={false}
        >Submit</LoaderButton>
      </form>


    </div>
  );
}

export default ContactForm;