const dev = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://bfxv98rhw3.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_YNPtrCDXW",
    APP_CLIENT_ID: "3hf3qcpcee4lnmhh2h0qfhrnj",
    IDENTITY_POOL_ID: "us-east-1:3047764f-19ea-49e8-b6d6-5cce235d2cf4"
  }
};

const prod = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://0oxak8h7oc.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Tc86Skqb0",
    APP_CLIENT_ID: "4hvnp95umjddit6v46gk57kg9j",
    IDENTITY_POOL_ID: "us-east-1:9f905c39-c4ef-488d-8944-b0e59d3dcaa0"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
