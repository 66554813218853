import React, { useState } from "react";
import { API } from "aws-amplify";
import ContactForm from "../components/ContactForm";

// import "./Settings.css";

export default function Contact(props) {
  const [isLoading, setIsLoading] = useState(false);



  async function handleFormSubmit(fields) {
    setIsLoading(true);
    const newUser = {
        ...fields
      };
      let myInit = { // OPTIONAL
        body: {
          newUser
        } // replace this with attributes you need
      }
    try {
      await API.post("notes", "/contact", myInit);
      alert("Thanks for reaching out");
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }
  
  return (
    <div className="Settings">
          <ContactForm
            isLoading={isLoading}
            onSubmit={handleFormSubmit}
          />
    </div>
  );
}