import React, { useRef, useState } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewNote.css";

export default function NewNote(props) {
  const file = useRef(null);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    // console.log('IN VALIDATE FORM', content)
    return content.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await createNote({ content });
      props.history.push("/");
    } catch (e) {
      // console.log(e);
      alert('There was a problem finding that stock, please ensure you entered the symbol exactly as shown on IEX');
      setIsLoading(false);
    }
  }

  function createNote(note) {
    // console.log(localStorage.getItem('alexaUserId'));
    return API.post("notes", "/stocks", {
      body: {
        symbol: content,
        user: localStorage.getItem('alexaUserId')
      }
    });
  }

  return (
    <div className="NewNote">
      <p>Our data comes from  <a href="https://iextrading.com/apps/stocks/" target="_blank">IEX</a> IEX. Search for available symbols on  <a href="https://iextrading.com/apps/stocks/" target="_blank">their site</a> and enter the symbol here:</p>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="content">
          <FormControl
          disabled={false}
            value={content}
            maxLength={5}
            // componentClass="text"
            onChange={e => setContent(e.target.value)}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </form>
    </div>
  );
}
