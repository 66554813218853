import React, { useState, useEffect, use } from "react";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem, Button, Glyphicon } from "react-bootstrap";
import "./Home.css";

export default function Home(props) {
  const [notes, setNotes] = useState([]);
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // const [alexaUserId, setAlexaUserId] = useProps(null)
  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }

      try {
        const stocks = await loadNotes();
        // console.log('STOCKS');
        // console.log(stocks);
        // console.log(props)
        let userStats;
        if (stocks[0]){
          // console.log(stocks[0].userStats)
          userStats = stocks[0].userStats;
          localStorage.setItem("alexaUserId", stocks[0].userID);
          setStats({...userStats});
          // console.log(localStorage.getItem("alexaUserId"));
          let stocksArr =[];
          Object.keys(stocks[0]).forEach(function(key) {
            var val = stocks[0][key];
            // console.log(val);
            // console.log(val.name !== undefined);
            if (val.name){
              stocksArr.push(val)
            }
          });
          setNotes(stocksArr);


        }
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadNotes() {
    let stocks = API.get("notes", "/stocks");
    return stocks;
  }
  async function handleClick(e){
    // console.log(e);
    // console.log(e.target.id);
    let id = e.target.id;
    e.preventDefault();

    setIsLoading(true);
    // console.log(e.currentTarget["id"]);
     let stocks = await API.del("notes", "/stocks/"+e.currentTarget["id"], {
      body: {
        symbol: e.currentTarget["id"],
        user: localStorage.getItem('alexaUserId')
      }});

    setIsLoading(false);
    // console.log(notes);
    // console.log(notes[0][id]);
    //setNotes(stocks);
    window.location.reload();
    return stocks;

  }
  function renderNotesList(notes) {
    // console.log(notes);
    // console.log(notes.length);
    // console.log('-=-=-=-=')
    // console.log(stats);
    let foo = [{}].concat(notes).map((note, i) =>
      i !== 0 ? (
        <div className="col-sm-12 col-md-12" style={{display:"flex"}}>
        <LinkContainer className="col-xs-10 col-sm-10 col-md-11" key={note.iexId+'-'+note.symbol} to={`.`}>
        {/* <div className="col-xs-8 col-md-10">
        <h4>{note.name}</h4>
        <p>{note.symbol.trim().split("\n")[0]}</p>
        </div>
        <div className="col-xs-4 col-md-2">
        <Button bsSize="small"><Glyphicon glyph="trash" /></Button>

        </div> */}
          <ListGroupItem header={note.name}>
            {note.symbol.trim().split("\n")[0]}
           </ListGroupItem>
        </LinkContainer>
        <Button onClick={handleClick} id={note.symbol} className="col-xs-2 col-sm-2 col-md-1" bsSize="small"><Glyphicon glyph="trash" /></Button>
        </div>
      ) : (
        <div className="col-sm-12 col-md-12">
        <p>You are currently tracking {notes.length} stocks. Your limit is {stats.limit} stocks. You have {stats.limit - notes.length} remaining slots in your portfolio.</p>
        {/* {console.log('NOTES LENGTH', notes.length)} */}
        {stats.limit !== notes.length ?
        <LinkContainer key="new" to="/notes/new">
          <ListGroupItem>
            <h4>
              <b>{"\uFF0B"}</b> Track a New Stock
            </h4>
          </ListGroupItem>
        </LinkContainer> 
        : <p>To purchase more slots for your portfolio say "Alexa, ask Stock Checker about the expansion packs"</p>}
        </div>
      )
    );
    return notes.length !== 0 ?  foo : renderLander();
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Stock Checker</h1>
        <p>If this is your first time visiting, be sure to bookmark  <a href="https://www.mystockchecker.com/" target="_blank">MyStockChecker.com</a>  and visit once you use Alexa and <a href="https://www.amazon.com/gp/product/B07NWLW3HG" target="_blank">Stock Checker</a> to track your first stock.</p>
        <p>If you have enabled the skill and tracked your first stock, click login to manage your portfolio!</p>
      </div>
    );
  }

  function renderNotes() {
    
    return (
      <div className="notes">
        <PageHeader>Your Stocks</PageHeader>
        <ListGroup>
          {!isLoading && renderNotesList(notes)}
        </ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {props.isAuthenticated ? renderNotes() : renderLander()}
    </div>
  );
}
